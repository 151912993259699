import React from 'react';
import { ScreenQuery, ScreenType } from '../../../gql/gqlRequests';
import * as ScreenContextDetails from './ScreenContextDetails';
import { getScreenTypeAndContext } from '../../../utils/screens';

type ScreenContextDetailFactoryProps = {
  screenData: ScreenQuery['screen'];
};

export default function ScreenContextDetailFactory({
  screenData,
}: ScreenContextDetailFactoryProps) {
  const { screenType, screenContext } = getScreenTypeAndContext(screenData);

  switch (screenType) {
    case ScreenType.Register:
      return (
        <ScreenContextDetails.RegisterScreenContextDetail
          screenContext={screenContext}
        />
      );

    case ScreenType.Home:
      return (
        <ScreenContextDetails.HomeScreenContextDetail
          screenContext={screenContext}
        />
      );

    case ScreenType.AudioPlayback:
      return (
        <ScreenContextDetails.AudioPlaybackScreenContextDetail
          screenContext={screenContext}
        />
      );

    case ScreenType.VideoPlayback:
      return (
        <ScreenContextDetails.VideoPlaybackScreenDetail
          screenContext={screenContext}
        />
      );

    case ScreenType.RecommendationsChannel:
      return (
        <ScreenContextDetails.RecommendationsChannelScreenContextDetail
          screenContext={screenContext}
        />
      );

    case ScreenType.AreYouStillWatching:
      return (
        <ScreenContextDetails.AreYouStillWatchingScreenContextDetail
          screenContext={screenContext}
        />
      );

    case ScreenType.SeriesDetail:
    case ScreenType.MovieDetail:
      return (
        <ScreenContextDetails.SeriesOrMovieDetailScreenContextDetail
          screenContext={screenContext}
        />
      );
    case ScreenType.LoginCode:
      return (
        <ScreenContextDetails.LoginCodeScreenContextDetail
          screenContext={screenContext}
        />
      );

    case ScreenType.Welcome:
      return (
        <ScreenContextDetails.WelcomeScreenContextDetail
          screenContext={screenContext}
        />
      );

    case ScreenType.HomeMiniPlayer:
      return (
        <ScreenContextDetails.HomeMiniPlayerScreenContextDetail
          screenContext={screenContext}
        />
      );

    case ScreenType.Splash:
    case ScreenType.Login:
    case ScreenType.ForgotPassword:
    case ScreenType.ForgotPasswordConfirmation:
    case ScreenType.ForgotPasswordExternal:
    case ScreenType.Browse:
    case ScreenType.BrowseB:
    case ScreenType.BrowseC:
    case ScreenType.BrowseD:
    case ScreenType.Favorites:
    case ScreenType.ContinueWatching:
    case ScreenType.Search:
    case ScreenType.Upsell:
    case ScreenType.Paywall:
    case ScreenType.ManageSubscription:
    case ScreenType.Account:
    case ScreenType.TermsService:
    case ScreenType.TermsPurchase:
    case ScreenType.Faq:
    case ScreenType.PrivacyPolicy:
    case ScreenType.CcpaPrivacy:
    case ScreenType.ThirdPartySoftware:
    case ScreenType.Support:
    case ScreenType.ForceUpdate:
    case ScreenType.AppExit:
    case ScreenType.LogoutConfirmation:
    case ScreenType.DeleteAccount:
    case ScreenType.DeleteAccountConfirmation:
    case ScreenType.PrivacyConsent:
    case ScreenType.AppSettings:
    case ScreenType.ParentalPin:
    case ScreenType.ParentalPinSet:
    case ScreenType.ParentalPinForgot:
    case ScreenType.PrivacyNoticeSplash:
    case ScreenType.Error:
      return (
        <ScreenContextDetails.SharedBackgroundImageScreenContextDetail
          screenContext={screenContext}
        />
      );

    default:
      throw new Error('Unknown Screen Type ' + screenType);
  }
}
